import { AvailableValues, Filter, Motor } from '../models';
import { INITIAL_POWER } from '../constants';

export const addPinnedMotors = (motors: Motor[], pinnedMotors: Motor[]) => pinnedMotors.concat(motors.filter(x => !pinnedMotors.map(y => y.id).includes(x.id)));

export const filterMotors = (motors: Motor[], hiddenIds: string[]) =>
    motors.filter(x => !hiddenIds.includes(x.id));

export const getInitialFilter = (): Filter => ({ power: INITIAL_POWER, speed: null, voltage: null, product: null });

export const getSelectedPower = (availableValues: AvailableValues, powerText: string) =>
    availableValues.powers.find(x => x >= Number(powerText)) ?? availableValues.powers[availableValues.powers.length - 1];

export const needAnimation = (motorIds: string[], hiddenMotorIds: string[], pinnedMotorIds: string[], motorId: string) => {
    const firstNotPinnedId = motorIds.find(x => !pinnedMotorIds.includes(x) && !hiddenMotorIds.includes(x));
    const lastPinnedIdWhichWouldBeFirst = motorIds.find(x => pinnedMotorIds[pinnedMotorIds.length - 1] === x
        && motorIds.find(y => !pinnedMotorIds.filter(z => z !== x).includes(y) && !hiddenMotorIds.includes(y)) === x);

    return ![firstNotPinnedId, lastPinnedIdWhichWouldBeFirst].includes(motorId);
};

export const resetSpeedFilter = (filter: Filter): Filter => ({ ...filter, speed: null });

export const resetVoltageFilter = (filter: Filter): Filter => ({ ...filter, voltage: null });

export const setPowerFilter = (filter: Filter, power: number): Filter => ({ ...filter, power });

export const setProductFilter = (filter: Filter, product: string): Filter => ({ ...filter, product });

export const setSpeedFilter = (filter: Filter, speed: string | null): Filter => ({ ...filter, speed });

export const setVoltageFilter = (filter: Filter, voltage: number | null): Filter => ({ ...filter, voltage });
