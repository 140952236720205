import { configureStore } from '@reduxjs/toolkit';
import { bannerReducer, layoutReducer, selectorReducer, snackbarReducer, translationReducer } from '.';

export const store = configureStore({
    reducer: {
        banners: bannerReducer,
        layout: layoutReducer,
        snackbars: snackbarReducer,
        selector: selectorReducer,
        translation: translationReducer
        
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
