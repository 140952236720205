import { ApiBase } from '.';
import { AvailableValues, Document, DocumentRequest, Filter, FilterResponse, ServiceDataResult } from '../models';

export class Api extends ApiBase {

    static getValues = (filter: Filter) => Api.post('api/line/values', filter) as Promise<ServiceDataResult<AvailableValues>>;

    static getMotorsAndValues = (filter: Filter) => Api.post('api/line', filter) as Promise<ServiceDataResult<FilterResponse>>;

    static getDocument = (request: DocumentRequest) => Api.post('api/line/getdocument', request) as Promise<ServiceDataResult<Document>>;

    static isProduction = () => Api.get('api/environment/production') as Promise<ServiceDataResult<boolean>>;
}
