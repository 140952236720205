import React, { AnimationEvent, ReactText, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { needAnimation, trackEvent } from '../../services';
import { Motor } from '../../models';
import { useAppSelector, useOpenAnimations, useTranslate } from '../../hooks/common';
import Button, { ButtonType } from '../common/Button';
import Icon, { IconType } from '../common/Icon';
import { IMAGES, TRANSLATIONS } from '../../constants';
import Tag, { TagColor } from '../common/Tag';
import { addHiddenId, addNewId, addPinned, removeNewId, removePinned, toggleMore } from '../../store';
import { Language } from '../../enums/Language';

enum CardAction {
    Pin,
    Remove
}

export enum CardType {
    Horizontal,
    Vertical
}

interface CardProps {
    motor: Motor
    type: CardType;
}

const Card = ({ motor, type }: CardProps) => {
    const dispatch = useDispatch();
    const dtkData = useAppSelector(state => state.selector.dtkData);
    const hiddenIds = useAppSelector(state => state.selector.hiddenIds);
    const filter = useAppSelector(state => state.selector.filter);
    const language = useAppSelector(state => state.translation.language);
    const moreOpen = useAppSelector(state => state.selector.moreOpen);
    const motors = useAppSelector(state => state.selector.motors ?? []);
    const newIds = useAppSelector(state => state.selector.newIds);
    const pinnedMotorIds = useAppSelector(state => state.selector.pinnedMotors.map(x => x.id));
    const translate = useTranslate();
    const [animationClass, handleAnimationEnd, contentRef, height] = useOpenAnimations<HTMLDivElement>(moreOpen);
    const [activeAction, setActiveAction] = useState(CardAction.Pin);
    const [disappearing, setDisappearing] = useState(false);
    const cartFormRef = useRef<HTMLFormElement>(null);
    const newMotor = newIds.includes(motor.id);
    const motorIds = motors.map(x => x.id);
    const pinnedMotor = pinnedMotorIds.includes(motor.id);
    const dataSheetAndDrawingUrl = process.env.REACT_APP_DATASHEET_AND_DRAWING_URL;
    const isCypress = !!('Cypress' in window);

    const getCardTypeClass = () => {
        switch (type) {
            case CardType.Horizontal:
                return 'card-horizontal';
            case CardType.Vertical:
                return 'card-vertical';
        }
    };

    const getMallCartContent = (mlfb: string) =>
        `<?xml version='1.0' encoding='UTF-8'?><!DOCTYPE cart SYSTEM 'cart_V2.dtd'><cart IF_VERSION='2.0'><item PRODUCT_ID='${mlfb}' QUANTITY='1' /></cart>`;

    const track = (name: string) => trackEvent({ name: name, properties: { mlfb: motor.mlfb } });

    const handlePinClick = () => {
        if (needAnimation(motorIds, hiddenIds, pinnedMotorIds, motor.id)) {
            setActiveAction(CardAction.Pin);
            setDisappearing(true);
        } else {
            pinnedMotor ? dispatch(removePinned(motor.id)) : dispatch(addPinned(motor));
        }
    };

    const handleTrashClick = () => {
        setActiveAction(CardAction.Remove);
        setDisappearing(true);
    };

    const handleCardAnimationEnd = () => {
        if (newMotor) {
            dispatch(removeNewId(motor.id));
        } else {
            setDisappearing(false);

            switch (activeAction) {
                case CardAction.Pin:
                    dispatch(addNewId(motor.id));
                    pinnedMotor ? dispatch(removePinned(motor.id)) : dispatch(addPinned(motor));
                    break;
                case CardAction.Remove:
                    motorIds.includes(motor.id) && dispatch(addHiddenId(motor.id));
                    pinnedMotor && dispatch(removePinned(motor.id));
                    break;
            }
        }
    };

    const handleMoreAnimationEnd = (event: AnimationEvent<HTMLDivElement>) => {
        event.stopPropagation();
        handleAnimationEnd();
    };

    const getPrice = (price: number) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const openWindowWithPost = (url: string, name: string, params: string) => {
        const form = document.createElement('form');
        const input = document.createElement('input');

        form.setAttribute('method', 'post');
        form.setAttribute('action', url);
        form.setAttribute('target', name);

        input.type = 'hidden';
        input.name = 'DATA';
        input.value = params;
        form.appendChild(input);

        document.body.appendChild(form);

        form.submit();

        document.body.removeChild(form);
    };

    const getMlfbWithOptions = (text: string) => {
        const mlfbWithOptionsSplit = motor.mlfbWithOptions.split(' ');
        const mlfb = mlfbWithOptionsSplit[0];
        const options = mlfbWithOptionsSplit[1].split('+').join('%2B');

        return `${mlfb}%2B${options}`;
    };

    const handleDataSheetAndDrawingSubmit = () => {
        const url = dataSheetAndDrawingUrl?.replace('{MLFB}', getMlfbWithOptions(motor.mlfbWithOptions));
        const target = isCypress ? '_self' : '_blank';

        window.open(url, target);
        track('DataSheetAndDrawing');
    };

    const handleConfigureOrModifySubmit = () => {
        if (dtkData) {
            openWindowWithPost(dtkData.resultUrl, '_blank', motor.dtkData);
            track('Modify');
        } else {
            openWindowWithPost(motor.configuratorLink.replace('#LANGUAGE#', Language[language].toUpperCase()), '_blank', '');
            track('Configure');
        }
    };

    const renderNameAndActions = () => (
        <div className='name-and-actions'>
            <div className='marketing-name' onClick={() => window.open(motor.marketingSite, '_blank')}>{motor.marketingName}</div>
            <div className='pin-and-remove'>
                <Button type={ButtonType.Tertiary} onClick={handlePinClick}>
                    <Icon type={pinnedMotor ? IconType.Pinned : IconType.Pin} />
                </Button>
                <Button type={ButtonType.Tertiary} onClick={handleTrashClick}>
                    <Icon type={IconType.Trash} />
                </Button>
            </div>
        </div>
    );

    const renderProperty = (label: ReactText, value: ReactText | JSX.Element, isLineProperty?: false) => (
        <div className='property'>
            <div className='property-label'>
                {label}
            </div>
            <div className={`property-value ${isLineProperty && type === CardType.Vertical ? 'double-height' : ''}`}>
                {value}
            </div>
        </div>
    );

    const renderPropertyWithIcon = (label: ReactText, value: ReactText | JSX.Element, isLineProperty?: false) => (
        <div className='property-container'>
            <Icon type={IconType.CheckSmall} />
            {renderProperty(label, value, isLineProperty)}
        </div>
    );

    const renderAdditionalProperties = () => (
        <>
            {type === CardType.Horizontal && renderPropertyWithIcon(translate(TRANSLATIONS.main.protection), motor.protection)}
            {renderPropertyWithIcon(translate(TRANSLATIONS.main.additionalProtection), motor.additionalProtection)}
            {renderPropertyWithIcon(translate(TRANSLATIONS.main.additionalCooling), motor.additionalCooling)}
            {renderPropertyWithIcon(translate(TRANSLATIONS.main.additionalExProtection), motor.additionalExProtection)}
        </>
    );

    const renderCartForm = () => (
        <form ref={cartFormRef} target='_blank' method='POST' encType='multipart/form-data' action='https://eb.automation.siemens.com/goos/catalog/Pages/ca01/intreceivepartslist.ashx'>
            <textarea hidden id='xmlpayload' name='xmlpayload' defaultValue={getMallCartContent(motor.mlfb)} />
        </form>
    );

    const renderPrice = () => (
        <div className='price-container'>
            <div className='price-item property-container'>
                <div className='property-value'>{motor.price === -1 ? translate(TRANSLATIONS.main.onRequest) : `${getPrice(motor.price)} ${translate(TRANSLATIONS.main.euro)}`}</div>
                <div className='property-label'>{translate(TRANSLATIONS.main.listPriceStartingFrom)}</div>
            </div>
            {motor.bestPrice &&
                <div className='single-tag'>
                    <Tag backgroundColor={TagColor.Blue} text={translate(TRANSLATIONS.main.bestPrice)} />
                </div>
            }
        </div>
    );

    const imagePath = IMAGES[`${motor.kmat}${motor.cooling}`] ?? IMAGES['engine1'];

    return (
        <div className={`card ${getCardTypeClass()} ${newMotor ? 'appearing' : ''} ${disappearing ? 'disappearing' : ''}`} onAnimationEnd={handleCardAnimationEnd}>
            <div className='thumbnail'>
                {type === CardType.Horizontal && renderNameAndActions()}
                <img src={imagePath} />
            </div>
            <div className='header'>
                {type === CardType.Vertical ? renderNameAndActions() : renderPrice()}
            </div>
            <div className='details'>
                {renderPropertyWithIcon(translate(TRANSLATIONS.main.mounting), motor.mounting)}
                {renderPropertyWithIcon(translate(TRANSLATIONS.main.exProtection), motor.exProtection)}
                {renderPropertyWithIcon(translate(TRANSLATIONS.main.cooling), motor.cooling)}
                {renderPropertyWithIcon(translate(TRANSLATIONS.main.typeOfCooling), motor.typeOfCooling)}
                {type === CardType.Vertical && renderPropertyWithIcon(translate(TRANSLATIONS.main.protection), motor.protection)}
                {type === CardType.Vertical &&
                    <>
                        <div ref={contentRef} className={`more-details ${animationClass}`} style={{ height }} onAnimationEnd={handleMoreAnimationEnd}>
                            {renderAdditionalProperties()}
                        </div>
                        <div className='details-toggle'>
                            <div className='single-tag'>
                                <Tag text={moreOpen ? translate(TRANSLATIONS.main.less) : translate(TRANSLATIONS.main.more)} onClick={() => dispatch(toggleMore())} />
                            </div>
                        </div>
                    </>
                }
            </div>
            {type === CardType.Horizontal
                ? (
                    <div className='details'>
                        {renderAdditionalProperties()}
                    </div>
                )
                : renderPrice()
            }
            <div className='actions'>
                <Button disabled={!motor.configuratorLink} type={ButtonType.Primary} onClick={handleConfigureOrModifySubmit}>
                    <Icon type={IconType.Configuration} />
                    {translate(dtkData ? TRANSLATIONS.main.modify : TRANSLATIONS.main.configure)}
                </Button>
                {renderCartForm()}
            </div>
        </div>
    );
};

export default Card;
