import React from 'react';
import { useDispatch } from 'react-redux';
import { apiCall, Api } from '../../services';
import { Filter } from '../../models';
import { TRANSLATIONS } from '../../constants';
import { useTranslate } from '../../hooks/common';
import { addSnackbar, clearHiddenIds, clearNewIds, setAvailableValues, setFilter, setMotors, toggleLoading } from '../../store';
import { SnackbarType } from '../../components/common/Snackbar';

export const useFilter = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();

    const handleGetAvailableValues = async (filter: Filter) => {
        dispatch(toggleLoading());

        await apiCall(
            Api.getValues(filter),
            async x => {
                dispatch(setAvailableValues(x.data));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.error.availableValues).toString(), type: SnackbarType.Error }));
            }
        );

        dispatch(toggleLoading());
    };

    const handleFilter = async (filter: Filter, clearMotors?: boolean) => {
        dispatch(toggleLoading());
        dispatch(setFilter(filter));
       
        await apiCall(
            Api.getMotorsAndValues(filter),
            async x => {
                dispatch(setFilter(x.data.filters));
                dispatch(setAvailableValues(x.data.availableValues));
                dispatch(setMotors(clearMotors ? [] : x.data.motors));
                dispatch(clearHiddenIds());
                dispatch(clearNewIds());
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.error.filter), type: SnackbarType.Error }));
            }
        );

        dispatch(toggleLoading());
    };

    return {
        onAvailableValuesGet: handleGetAvailableValues,
        onFilter: handleFilter
    };
};
