import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Layout from './ts/components/layout/Layout';
import Selector from './ts/components/application/Selector';

const routes = (
    <Routes>
        <Route path='/' element={<Layout><Outlet /></Layout>}>
            <Route path='' element={<Selector />} />
        </Route>
    </Routes>
);

export default routes;
