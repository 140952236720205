import { configureHooks } from '../providers';
import { useDocumentDownload, useEnvironment, useFilter, useFilterInit } from './application';

export const hooks = configureHooks({
    documentDownload: { hook: useDocumentDownload },
    environment: { hook: useEnvironment, global: true },
    filter: { hook: useFilter },
    filterInit: { hook: useFilterInit }
});

export const useHook = hooks.useHook;
