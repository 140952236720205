import { Translations } from '../models';

const getTranslations = <T extends Translations>(translations: T): T => translations;

export const TRANSLATIONS = getTranslations({
    'error': {
        'availableValues': [
            'Cannot get available values',
            'Cannot get available values'
        ],
        'documentDownload': [
            'An error occured during the document download',
            'An error occured during the document download'
        ],
        'environment': [
            'An error occured during environment request',
            'An error occured during environment request'
        ],
        'filter': [
            'An error occured during filtering',
            'An error occured during filtering'
        ],
        'optionValidation': [
            'Cannot validate options',
            'Cannot validate options'
        ]
    },
    'footer': {
        'contactUs': [
            'Contact us',
            'Kontakt'
        ],
        'contactUsLink': [
            'https://www.lda-portal.siemens.com/siemlda/en/contact/map',
            'https://www.lda-portal.siemens.com/siemlda/de/contact/map'
        ],
        'cookieNotice': [
            'Cookie Notice',
            'Cookie-Hinweise'
        ],
        'cookieNoticeLink': [
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/de/general/cookie-richtlinien.html'
        ],
        'corporateInformation': [
            'Corporate Information',
            'Impressum'
        ],
        'corporateInformationLink': [
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/de/general/legal.html'
        ],
        'digitalId': [
            'Digital ID',
            'Digitales Zertifikat'
        ],
        'digitalIdLink': [
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/de/general/digitales-zertifikat.html'
        ],
        'siemensWebsite': [
            'siemens.com Global Website',
            'siemens.com Germany'
        ],
        'siemensWebsiteLink': [
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/de.html'
        ],
        'privacyNotice': [
            'Privacy Notice',
            'Datenschutz'
        ],
        'privacyNoticeLink': [
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/de/general/datenschutz.html'
        ],
        'siemens': [
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}'
        ],
        'termsOfUse': [
            'Terms of use',
            'Nutzungsbedingungen'
        ],
        'termsOfUseLink': [
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/de/general/nutzungsbedingungen.html'
        ]
    },
    'header': {
        'language': [
            'Language',
            'Sprache'
        ],
        'languages': {
            'en': [
                'English',
                'English'
            ],
            'de': [
                'Deutsch',
                'Deutsch'
            ]
        },
        'skipToMainContent': [
            'Skip to main content',
            'Skip to main content'
        ],
        'support': [
            'Support',
            'Unterstützung'
        ],
        'reportProblem': [
            'Report a problem',
            'Ein Problem melden'
        ]
    },
    'main': {
        'additionalCooling': [
            'Optional type of cooling',
            'Optional type of cooling'
        ],
        'additionalProtection': [
            'Optional protection',
            'Optional protection'
        ],
        'additionalExProtection': [
            'Optional ex-protection',
            'Optional ex-protection'
        ],
        'addToCart': [
            'Add to cart',
            'Add to cart'
        ],
        'bestPrice': [
            'Best price',
            'Best price'
        ],
        'configure': [
            'Configure',
            'Configure'
        ],
        'cooling': [
            'Cooling',
            'Cooling'
        ],
        'dataSheet': [
            'Datasheet',
            'Datasheet'
        ],
        'drawing': [
            'Drawing',
            'Drawing'
        ],
        'euro': [
            '\u20AC',
            '\u20AC'
        ],
        'exProtection': [
            'Ex-protection',
            'Ex-protection'
        ],
        'areaClassification': [
            'Area Classification',
            'Area Classification'
        ],
        'optionalAreaClassification': [
            'Optional Area Classification',
            'Optional Area Classification'
        ],
        'filterActive': [
            'Active filter',
            'Active filter'
        ],
        'kv': [
            'kV',
            'kV'
        ],
        'less': [
            'Less',
            'Less'
        ],
        'listPriceStartingFrom': [
            'List price starting from',
            'List price starting from'
        ],
        'modify': [
            'Modify',
            'Modify'
        ],
        'more': [
            'More',
            'More'
        ],
        'motorFeatures': [
            'Motor features',
            'Motor features'
        ],
        'standards': {
            'iec': [
                'IEC',
                'IEC'
            ],
            'anema': [
                'ANEMA',
                'ANEMA'
            ]
        },
        'mounting': [
            'Type of construction',
            'Type of construction'
        ],
        'noResults': [
            'No results',
            'No results'
        ],
        'onRequest': [
            'On request',
            'On request'
        ],
        'pleaseEnterMotorData': [
            'Please enter motor data',
            'Please enter motor data'
        ],
        'power': [
            'Power',
            'Power'
        ],
        'protection': [
            'Protection',
            'Protection'
        ],
        'rpm': [
            'Rpm',
            'Rpm'
        ],
        'synchronousSpeed': [
            'Synchronous speed',
            'Synchronous speed'
        ],
        'typeOfCooling': [
            'Type of cooling',
            'Type of cooling'
        ],
        'additionalTypeOfCooling': [
            'Optional type of cooling',
            'Optional type of cooling'
        ],
        'voltage': [
            'Voltage',
            'Voltage'
        ]
    }
});
