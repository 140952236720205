import React from 'react';
import { useDispatch } from 'react-redux';
import { getInitialFilter, resetSpeedFilter, resetVoltageFilter } from '../../services';
import { INITIAL_POWER, TRANSLATIONS } from '../../constants';
import { addNewIds, clearHiddenIds, clearNewIds, clearPinned, setAvailableValues, setFilter, setPowerText, toggleVerticalView } from '../../store';
import { ButtonType } from '../common/Button';
import ButtonGroup, { GroupButton } from '../common/ButtonGroup';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon, { IconType } from '../common/Icon';
import { TagColor, TagProps } from '../common/Tag';
import TagBar from '../common/TagBar';
import { useAppSelector, useTranslate } from '../../hooks/common';
import { useHook } from '../../hooks';

interface SelectorTagBarProps {
    backgroundColor: TagColor;
}

const SelectorTagBar = ({ backgroundColor }: SelectorTagBarProps) => {
    const dispatch = useDispatch();
    const filter = useAppSelector(state => state.selector.filter);
    const hiddenIds = useAppSelector(state => state.selector.hiddenIds);
    const initialAvailableValues = useAppSelector(state => state.selector.initialAvailableValues);
    const loading = useAppSelector(state => state.selector.loading);
    const { onFilter } = useHook(x => x.filter)();
    const translate = useTranslate();
    const verticalView = useAppSelector(state => state.selector.verticalView);

    const handleFilterReset = () => {
        dispatch(addNewIds(hiddenIds));
        dispatch(clearHiddenIds());
    };

    const handlePowerReset = () => {
        const initialFilter = { ...getInitialFilter(), product: filter.product ?? null };
        dispatch(setFilter(initialFilter));
        dispatch(setPowerText(INITIAL_POWER.toString()));
        dispatch(clearHiddenIds());
        initialAvailableValues && dispatch(setAvailableValues(initialAvailableValues));
        onFilter(initialFilter, true);
    };

    const handleSpeedReset = () => {
        const newFilter = resetSpeedFilter(filter);

        dispatch(setFilter(newFilter));
        onFilter(newFilter);
    };

    const handleVoltageReset = () => {
        const newFilter = resetVoltageFilter(filter);

        dispatch(setFilter(resetVoltageFilter(newFilter)));
        onFilter(newFilter);
    };

    const getTagProps = (text: string, onClick: () => void): TagProps => ({ text, onClick, disabled: loading, close: true });

    const tags: TagProps[] = [
        ...hiddenIds.length
            ? [getTagProps(translate(TRANSLATIONS.main.filterActive), handleFilterReset)]
            : [],
        getTagProps(`${filter?.power} kW`, handlePowerReset),
        ...filter?.voltage
            ? [getTagProps(`${filter?.voltage} ${translate(TRANSLATIONS.main.kv)}`, handleVoltageReset)]
            : [],
        ...filter?.speed
            ? [getTagProps(`${filter?.speed} ${translate(TRANSLATIONS.main.rpm)}`, handleSpeedReset)]
            : []
    ];

    const viewButtons: GroupButton[] = [
        { children: <Icon type={IconType.VerticalView} />, disabled: verticalView, onClick: () => dispatch(toggleVerticalView()) },
        { children: <Icon type={IconType.HorizontalView} />, disabled: !verticalView, onClick: () => dispatch(toggleVerticalView()) }
    ];

    const renderTagBarActions = () => (
        <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10}>
            <ButtonGroup type={ButtonType.Secondary} buttons={viewButtons} />
        </Flex>
    );

    return (
        <div className='selector-tag-bar'>
            <TagBar backgroundColor={backgroundColor} tags={tags} actions={renderTagBarActions()} />
        </div>
    );
};

export default SelectorTagBar;
