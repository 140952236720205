import React from 'react';
import { SectionColor } from './Section';
import Tag, { TagProps, TagColor } from './Tag';

interface TagBarProps {
    backgroundColor: TagColor;
    tags: TagProps[];
    actions?: JSX.Element;
}

const TagBar = ({ actions, backgroundColor, tags }: TagBarProps) => {
    const getBackgroundClass = () => {
        switch (backgroundColor) {
            case TagColor.Blue:
                return 'blue';
            case TagColor.DeepBlue:
                return 'deep-blue';
            case TagColor.LightSand:
                return 'light-sand';
            case TagColor.Transparent:
                return 'transparent';
            case TagColor.White:
                return 'white';
        }
    };

    return (
        <div className={`tag-bar ${getBackgroundClass()}`}>
            <div className='tags-container'>
                <div className='tags'>
                    {tags.map(x => <Tag key={x.text} {...x} />)}
                </div>
            </div>
            {actions &&
                <div className='tag-bar-actions'>
                    {actions}
                </div>
            }
        </div>
    );
};

export default TagBar;
