import React, { KeyboardEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Language } from '../../enums';
import { TRANSLATIONS, TABS } from '../../constants';
import { useAppSelector, useResized, useScrolledPast, useTranslate } from '../../hooks/common';
import HeaderAction, { HeaderActionProps, HeaderDropdownAction } from './HeaderAction';
import HeaderMegaMenu from './HeaderMegaMenu';
import HeaderTabs from './HeaderTabs';
import Banners from '../common/Banners';
import ContentContainer from '../common/ContentContainer';
import { IconType } from '../common/Icon';
import MenuIcon from '../common/MenuIcon';
import siemensLogo from '../../../assets/images/siemens-logos/siemens-logo-petrol.svg';
import { removeBanner, setLanguage } from '../../store';

type LanguageType = 'en' | 'de';

interface HeaderProps {
    title?: string;
}

const Header = ({ title }: HeaderProps) => {
    const dispatch = useDispatch();
    const banners = useAppSelector(state => state.banners.banners);
    const [menuOpen, setMenuOpen] = useState(false);
    const mobileView = useResized(x => x <= 767);
    const scrolled = useScrolledPast();
    const translate = useTranslate();
    const languages = Object.keys(Language).slice(0, Math.ceil(Object.keys(Language).length / 2)).map(x => Number(x));

    const handleSkipButtonKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();

            location.hash = '';
            location.hash = '#main';
        }
    };

    const handleLanguageChange = (language: Language) => {
        dispatch(setLanguage(language));
        window.localStorage.setItem('language', language.toString());
    };
    
    const headerActions: HeaderActionProps[] = [
        {
            dropdownActions: languages.map<HeaderDropdownAction>(x => ({ name: translate(TRANSLATIONS.header.languages[Language[x].toLowerCase() as LanguageType]), onClick: () => handleLanguageChange(x) })),
            iconType: IconType.Language,
            title: translate(TRANSLATIONS.header.language)
        },
        {
            dropdownActions: [{ name: translate(TRANSLATIONS.header.reportProblem), iconType: IconType.Email, onClick: () => window.location.href = 'mailto:apps-help.industry@siemens.com' }],
            iconType: IconType.Support,
            title: translate(TRANSLATIONS.header.support)
        }
    ];

    return (
        <header>
            <div className='main-row-container'>
                <ContentContainer header>
                    <div className='main-row'>
                        <div className='skip-button' tabIndex={0} onKeyDown={handleSkipButtonKeyDown}>
                            {translate(TRANSLATIONS.header.skipToMainContent)}
                        </div>
                        <div className='header-row-container'>
                            <img className='siemens-logo' src={siemensLogo} alt='Siemens Logo' />
                        </div>
                        {title &&
                            <div className='header-row-container'>
                                <div className='title'>{title}</div>
                            </div>
                        }
                        <div className='action-container'>
                            {headerActions.map(x => <HeaderAction key={x.title} dropdownActions={x.dropdownActions} iconType={x.iconType} title={x.title} />)}
                            <div className='header-row-container'>
                                <MenuIcon open={menuOpen} onClick={() => setMenuOpen(x => !x)} />
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </div>
            <div className={`addtitional-rows-container ${scrolled ? 'hiden' : ''}`}>
                <div className='secondary-row-container'>
                    <ContentContainer header>
                        <div className='secondary-row'>
                            <HeaderTabs hidden={scrolled || mobileView} tabs={TABS} />
                        </div>
                    </ContentContainer>
                </div>
                <Banners banners={banners} removeBanner={x => dispatch(removeBanner(x))} />
            </div>
            <HeaderMegaMenu navigationItems={TABS.map(x => ({ ...x }))} open={menuOpen} onClose={() => setMenuOpen(false)} />
        </header>
    );
};

export default Header;
