import React, { KeyboardEvent } from 'react';
import Icon, { IconType } from './Icon';

export enum TagColor {
    Blue,
    DeepBlue,
    LightSand,
    Transparent,
    White
}

export interface TagProps {
    text: string;
    close?: boolean;
    backgroundColor?: TagColor;
    dark?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const Tag = ({ close, backgroundColor, dark, disabled, text, onClick }: TagProps) => {
    const handleSelect = () => {
        if (onClick && !disabled) {
            onClick();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === ' ') {
            event.preventDefault();
            handleSelect();
        }
    };

    const getBackgroundClass = () => {
        switch (backgroundColor) {
            case TagColor.Blue:
                return 'blue';
            case TagColor.DeepBlue:
                return 'deep-blue';
            case TagColor.LightSand:
                return 'light-sand';
            case TagColor.Transparent:
                return 'transparent';
            case TagColor.White:
                return 'white';
        }
    };

    return (
        <div className={`tag ${disabled ? 'disabled' : ''} ${onClick ? '' : 'display'} ${dark ? 'dark' : ''} ${getBackgroundClass()}`} tabIndex={onClick && !disabled ? 0 : undefined} onClick={handleSelect}
            onKeyDown={handleKeyDown}>
            <div className='tag-text'>{text}</div>
            {close && <Icon type={IconType.CloseSmall} />}
        </div>
    );
};

export default Tag;
