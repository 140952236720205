import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiCall, Api, setProductFilter } from '../../services';
import { KEEP_ALIVE_URL, RESULT_URL, TRANSLATIONS } from '../../constants';
import { HeaderTabType } from '../../enums';
import { SnackbarType } from '../../components/common/Snackbar';
import { useAppSelector, useTranslate } from '../../hooks/common';
import { useSearchParams } from 'react-router-dom';
import { addSnackbar, setActiveTabType, setDtkData, setFilter, setInitialAvailableValues, toggleLoading } from '../../store';

export const useFilterInit = () => {
    const dispatch = useDispatch();
    const filter = useAppSelector(state => state.selector.filter);
    const translate = useTranslate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        (async () => {
            const [keepAliveUrl, resultUrl] = [KEEP_ALIVE_URL, RESULT_URL].map(x => searchParams.get(x));

            const product = getProductFromQueryString();
            const newFilter = product ? setProductFilter(filter, product) : filter;

            window.scrollTo(0, 0);
            dispatch(toggleLoading());
            dispatch(setFilter(newFilter));
            dispatch(setActiveTabType(HeaderTabType.Selector));
            keepAliveUrl && resultUrl && dispatch(setDtkData({ keepAliveUrl, resultUrl }));

            await apiCall(
                Api.getValues(newFilter),
                async x => {
                    dispatch(setInitialAvailableValues(x.data));
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.error.availableValues), type: SnackbarType.Error }));
                }
            );

            dispatch(toggleLoading());
        })();
    }, []);

    const getProductFromQueryString = () => {
        const params = searchParams;
        const newParams = new URLSearchParams();

        params.forEach((value, name) => {
            newParams.append(name.toLowerCase(), value);
        });

        const productValue = newParams.get('product');

        return productValue ? productValue.toUpperCase() : null;
    };
};
