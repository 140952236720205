import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ResponsiveObject } from 'react-slick';
import Slider from 'rc-slider';
import { addPinnedMotors, filterMotors, getInitialFilter, getSelectedPower, setPowerFilter, setSpeedFilter, setVoltageFilter } from '../../services';
import { useAppSelector, useResized, useTranslate } from '../../hooks/common';
import Card, { CardType } from './Card';
import SelectorTagBar from './SelectorTagBar';
import Carousel from '../common/Carousel';
import ContentContainer from '../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Jumbotron from '../common/Jumbotron';
import KeyVisual from '../common/KeyVisual';
import Loader from '../common/Loader';
import Section, { SectionColor } from '../common/Section';
import Select from '../common/Select';
import TextInput from '../common/TextInput';
import Toggle from '../common/Toggle';
import keyVisual from '../../../assets/images/background.jpg';
import { INITIAL_POWER, TRANSLATIONS } from '../../constants';
import { useHook } from '../../hooks';
import { TagColor } from '../common/Tag';
import { ComponentSize } from '../../enums';
import { clearHiddenIds, clearNewIds, clearPinned, setFilter, setPower, setPowerText, toggleVerticalView } from '../../store';

const Selector = () => {
    const dispatch = useDispatch();
    const initialAvailableValues = useAppSelector(state => state.selector.initialAvailableValues);
    const availableValues = useAppSelector(state => state.selector.availableValues) ?? initialAvailableValues;
    const dtkData = useAppSelector(state => state.selector.dtkData);
    const filter = useAppSelector(state => state.selector.filter);
    const loading = useAppSelector(state => state.selector.loading);
    const hiddenIds = useAppSelector(state => state.selector.hiddenIds);
    const motors = useAppSelector(state => state.selector.motors ?? []);
    const { onFilter } = useHook(x => x.filter)();
    const pinnedMotors = useAppSelector(state => state.selector.pinnedMotors);
    const powerText = useAppSelector(state => state.selector.powerText);
    const translate = useTranslate();
    const verticalView = useAppSelector(state => state.selector.verticalView);
    const disableHorizontalView = useResized(x => x < 1200);
    const dtkKeepAliveIframe = useRef<HTMLIFrameElement>(null);
    const filteredMotors = filterMotors(motors, hiddenIds);
    const motorsToDisplay = addPinnedMotors(filteredMotors, pinnedMotors);

    const carouselResponsive: ResponsiveObject[] = [
        { breakpoint: 1330, settings: { slidesToShow: 3, slidesToScroll: 3 } },
        { breakpoint: 1007, settings: { slidesToShow: 2, slidesToScroll: 2 } },
        { breakpoint: 683, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    ];

    useHook(x => x.filterInit)();

    useEffect(() => {
        dtkData && setInterval(() => {
            if (dtkKeepAliveIframe.current) {
                dtkKeepAliveIframe.current.src;
            }
        }, 60000);
    }, [dtkData]);

    useEffect(() => {
        disableHorizontalView && !verticalView && dispatch(toggleVerticalView());
    }, [disableHorizontalView]);


    const handleSliderChange = (value: number | number[]) => {
        const selectedPower = availableValues?.powers[typeof value === 'number' ? value : value[0]] ?? 0;

        dispatch(setPower(selectedPower));
        dispatch(setPowerText(selectedPower.toString()));
    };

    const handlePowerInputBlur = () => {
        if (powerText) {
            if (availableValues) {
                const selectedPower = getSelectedPower(availableValues, powerText);

                dispatch(setPower(filter.power ?? INITIAL_POWER));
                handlePowerSubmit(selectedPower);
            }
        } else {
            dispatch(setPowerText(filter.power?.toString() ?? ''));
        }
    };

    const handlePowerOnAfterChange = (powerIndex: number | number[]) => {
        const power = availableValues?.powers[typeof powerIndex === 'number' ? powerIndex : powerIndex[0]] ?? filter.power;

        handlePowerSubmit(power);
    };

    const handlePowerSubmit = (power: number) => {
        const newFilter = setPowerFilter(filter, power);

        onFilter(newFilter);
    };

    const handleSpeedSelect = (speed: string | null) => {
        const newFilter = setSpeedFilter(filter, speed);

        dispatch(setFilter(newFilter));
        onFilter(newFilter);
    };

    const handleVoltageSelect = (voltage: number | null) => {
        const newFilter = setVoltageFilter(filter, voltage);

        dispatch(setFilter(newFilter));
        onFilter(newFilter);
    };

    const renderPowerInput = () => (
        <div className='power-slider'>
            <Slider min={0} max={availableValues ? availableValues?.powers.length - 1 : 0} value={availableValues?.powers.indexOf(getSelectedPower(availableValues, filter.power.toString())) ?? 0}
                disabled={loading} onChange={x => handleSliderChange(x)} onAfterChange={x => handlePowerOnAfterChange(x)} />
            <TextInput name='power' label={`${translate(TRANSLATIONS.main.power)} [kW]`} value={powerText} disabled={loading} onBlur={handlePowerInputBlur}
                onChange={x => !isNaN(Number(x)) && dispatch(setPowerText(x))} />
        </div>
    );

    const renderVoltageInput = () => (
        <Select label={translate(TRANSLATIONS.main.voltage)} value={filter?.voltage ?? null} values={availableValues?.voltages ?? []}
            disabled={loading} mapToString={x => `${x?.toString()} ${translate(TRANSLATIONS.main.kv)}` ?? ''} onSelect={handleVoltageSelect} required />
    );

    const renderSpeedInput = () => (
        <Select label={`${translate(TRANSLATIONS.main.synchronousSpeed)} [${translate(TRANSLATIONS.main.rpm)}]`} value={filter?.speed} values={availableValues?.speeds ?? []}
            disabled={loading} mapToString={x => x?.toString() ?? ''} onSelect={handleSpeedSelect} required />
    );

    return (
        <div className='selector'>
            <KeyVisual height={360} url={keyVisual}>
                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center}>
                    <ContentContainer>
                        <Jumbotron>
                            <div className='primary-filters'>
                                <div className='title-and-speed-delivery'>
                                    <div className='title'>{translate(TRANSLATIONS.main.motorFeatures)}</div>
                                </div>
                                {renderPowerInput()}
                                <div className='speed-and-all-filters'>
                                    {renderVoltageInput()}
                                    {renderSpeedInput()}
                                </div>
                            </div>
                        </Jumbotron>
                    </ContentContainer>
                </Flex>
            </KeyVisual>
            <Section color={SectionColor.LightSand} style={{ paddingTop: 15 }}>
                <ContentContainer>
                    <SelectorTagBar backgroundColor={TagColor.Transparent} />
                    <div className='results'>
                        <Loader loading={loading} margin={5} marginBottom={motorsToDisplay.length && verticalView ? 35 : 5} marginLeft={30} marginRight={30} transparent={!motorsToDisplay.length}>
                            {motorsToDisplay.length
                                ? verticalView
                                    ? <Carousel slidesToShow={4} responsive={carouselResponsive} dots={motorsToDisplay.length < 20}>
                                        {motorsToDisplay.map(x =>
                                            <div key={x.id}>
                                                <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                                                    <Card key={x.id} motor={x} type={CardType.Vertical} />
                                                </Flex>
                                            </div>
                                        )}
                                    </Carousel>
                                    : <Flex direction={FlexDirection.Column} justification={FlexJustification.Center} gap={10}>
                                        {motorsToDisplay.map(x =>
                                            <Card key={x.id} motor={x} type={CardType.Horizontal} />
                                        )}
                                    </Flex>
                                : <div className='results-placeholder'>
                                    {loading ? <br /> : translate(motors.length ? TRANSLATIONS.main.noResults : TRANSLATIONS.main.pleaseEnterMotorData)}
                                </div>
                            }
                        </Loader>
                    </div>
                </ContentContainer>
            </Section>
            {dtkData &&
                <iframe ref={dtkKeepAliveIframe} className='dtkKeepAliveIframe' src={dtkData.keepAliveUrl} />
            }
        </div>
    );
};

export default Selector;
