import React from 'react';
import Engine1 from '../../assets/images/motors/engine1.png';
import Engine2 from '../../assets/images/motors/engine2.png';
import Engine3 from '../../assets/images/motors/engine3.png';
import Engine4 from '../../assets/images/motors/engine4.png';
import Engine5 from '../../assets/images/motors/engine5.png';
import Hacompp from '../../assets/images/motors/HACOMPP.png';
import HacomppAir from '../../assets/images/motors/HACOMPP_Air.png';
import HacomppWater from '../../assets/images/motors/HACOMPP_Water.png';
import HacompsAir from '../../assets/images/motors/HACOMPS_Air.png';
import HacompsWater from '../../assets/images/motors/HACOMPS_Water.png';
import HhcompAir from '../../assets/images/motors/HHCOMP_Air.png';
import HhcompWater from '../../assets/images/motors/HHCOMP_Water.png';
import HhcomppAir from '../../assets/images/motors/HHCOMPP_Air.png';
import HhcomppWater from '../../assets/images/motors/HHCOMPP_Water.png';
import HhmodAir from '../../assets/images/motors/HHMOD_Air.png';
import HhmodWater from '../../assets/images/motors/HHMOD_Water.png';
import HhvcompAir from '../../assets/images/motors/HHVCOMP_Air.png';
import HhvcompWater from '../../assets/images/motors/HHVCOMP_Water.png';
import HhvmodAir from '../../assets/images/motors/HHVMOD_Air.png';
import HhvmodWater from '../../assets/images/motors/HHVMOD_Water.png';
import HhvnemaAir from '../../assets/images/motors/HHVNEMA_Air.png';
import { Dictionary } from '../models';

export const IMAGES: Dictionary<string> = {
    'Engine1': Engine1,
    'Engine2': Engine2,
    'Engine3': Engine3,
    'Engine4': Engine4,
    'Engine5': Engine5,
    'HACOMPP': Hacompp,
    'HACOMPPAir': HacomppAir,
    'HACOMPPWater': HacomppWater,
    'HACOMPSAir': HacompsAir,
    'HACOMPSWater': HacompsWater,
    'HHCOMPAir': HhcompAir,
    'HHCOMPWater': HhcompWater,
    'HHCOMPPAir': HhcomppAir,
    'HHCOMPPWater': HhcomppWater,
    'HHMODAir': HhmodAir,
    'HHMODWater': HhmodWater,
    'HHVCOMPAir': HhvcompAir,
    'HHVCOMPWater': HhvcompWater,
    'HHVMODAir': HhvmodAir,
    'HHVMODWater': HhvmodWater,
    'HHVNEMAAir': HhvnemaAir
};
