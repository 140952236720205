import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';

export const trackEvent = (event: IEventTelemetry) => {
    const windowAsAny = window as any;
    const applicationInsights: ApplicationInsights | null = windowAsAny.isProduction ? windowAsAny.appInsights : null;

    try {
        applicationInsights?.trackEvent(event);
    } catch (e) { }
};
