import React, { ReactNode } from 'react';
import Button, { ButtonType } from '../common/Button';

export interface GroupButton {
    children: ReactNode;
    onClick: () => void;
    disabled?: boolean;
}

interface ButtonGroupProps {
    buttons: GroupButton[];
    type: ButtonType;
    dark?: boolean;
}

const ButtonGroup = ({ buttons, dark, type }: ButtonGroupProps) => (
    <div className='button-group'>
        {buttons.map((x, i) =>
            <Button key={i} dark={dark} disabled={x.disabled} type={type} onClick={x.onClick}>
                {x.children}
            </Button>
        )}
    </div>
);

export default ButtonGroup;
