import React, { ReactNode } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';

interface CarouselProps {
    slidesToShow: number;
    beforeChange?: (currentSlide: number, nextSlide: number) => void;
    children?: ReactNode;
    dark?: boolean;
    responsive?: ResponsiveObject[];
    dots?: boolean;
}

const Carousel = ({ beforeChange, children, dots, dark, responsive, slidesToShow }: CarouselProps) => (
    <div className={`carousel ${dark ? 'dark' : ''}`}>
        <Slider slidesToShow={slidesToShow} slidesToScroll={slidesToShow} speed={500} infinite={false} responsive={responsive} beforeChange={beforeChange} arrows dots={dots ?? true}>
            {children}
        </Slider>
    </div>
);

export default Carousel;
