import React from 'react';
import { useDispatch } from 'react-redux';
import { apiCall, Api, saveDocument } from '../../services';
import { addSnackbar, toggleLoading } from '../../store';
import { DocumentRequest } from '../../models';
import { TRANSLATIONS } from '../../constants';
import { SnackbarType } from '../../components/common/Snackbar';
import { useTranslate } from '../../hooks/common';

export const useDocumentDownload = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();

    const handleDocumentDownload = async (documentRequest: DocumentRequest) => {
        dispatch(toggleLoading());

        apiCall(
            Api.getDocument(documentRequest),
            async x => {
                saveDocument(x.data);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.error.documentDownload).toString(), type: SnackbarType.Error }));
            }
        );

        dispatch(toggleLoading());
    };  

    return {
        onDocumentDownload: handleDocumentDownload
    };
};
